<template>
  <div class="head">
    <div class="wrap top">
      <div id="logo" @click="go()"><img alt="迪韵" src="../assets/logo.png" /></div>
      <nav>
        <ul class="nav">
          <li v-for="(item, index) in navList" :key="index">
            <router-link :to="item.link">{{ item.title }}</router-link>
          </li>
        </ul>
      </nav>
      <div class="login-reg" @click="login">
        <a-icon type="user" class="logico" :style="{ fontSize: '14px', color: '#333' }" />
        {{ userInfo }}
      </div>
      <div class="shoppingCart" @click="shoppingcart"> <a-icon type="shopping-cart" class="logico" :style="{ fontSize: '14px', color: '#333','padding-left':'30px' }" />购物车</div>
    </div>
</div>
</template>

<script>

export default {
  name: "Nav",
  data() {
    return {
      userInfo: "注册/登录",
      user: {},
      navList: [
        {
          title: "首页",
          link: "/",
        },
        {
          title: "运单跟踪",
          link: "/trace",
        },
        {
          title: "我要寄件",
          link: "/post",
        },
        {
          title: "收费标准",
          link: "/charge",
        },
        {
          title: "服务网点",
          link: "/service",
        },{
          title: "商城",
          link: "/mall",
        },
        {
          title: "同城",
          link: "/Intracity",
        },
        {
          title: "攻略",
          link: "/Strategy",
        },
        {
          title: "关于我们",
          link: "/about",
        },
      ],
    };
  },
  props: {
    navList2: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (this.user) {
      this.userInfo = this.user.name;
    }
  },
  methods: {
    go() {
      this.$router.push('/Home');
    },
    login() {
      this.$router.push("/user");
    },
    shoppingcart(){
      this.$router.push("/shoppingCart");
    }
  },
};
</script>

<style>
.head {
  /* background: url("../assets/nav.png") no-repeat center; */
  margin-bottom: 0.1em;
  height: 73px;
  background: #fff;
}

#logo {
  width: 90px;
}

#logo img {
  height: 40px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav {
  flex: 1;
}

.nav {
  display: flex;
  align-items: center;
  height: 73px;
  font-size: 14px;
  margin-left: 30px;
  margin-bottom: 0;
}

.nav li {
  padding: 0 20px;
}

.nav a {
  color: #333;
}

.nav a:hover {
  color: #dc1e32;
}

.login-reg {
  margin-left: 100px;
  cursor: pointer;
}

.logico {
  padding-right: 2px;
}
</style>