import Vue from 'vue'
import { get, post } from './http.interceptor.js'
import qs from 'qs';

// 如果没有通过拦截器配置域名的话，可以在这里写上完整的URL(加上域名部分)
let getwxopenIdUrl = 'user/getwxpcopenId';
let weixinLoginUrl = 'user/weixinLogin';
let weixinxcxLoginUrl = 'user/xcxLogin';
let weixinxcxRegisteredUrl = 'user/xcxRegistered';
let sendVercodeUrl = 'user/sendVercode';
let sendPassWordVercodeUrl = 'user/sendPassWordVercode';
let vercodeChangePasswordUrl = 'user/vercodeChangePassword';
let vercodeLoginUrl = 'user/vercodeLogin';
let registerUrl = 'user/Registered';

let postersUrl = '/getposters';
let articleUrl = '/getarticle';
let articlesUrl = '/getarticles';
let articlecategoryUrl = '/getarticlecategory';
let articlesPageUrl = '/getarticlespage';
let getCitys2PageUrl = '/getCity2';

let getwaybilldictionariesPageUrl = 'waybill/getwaybilldictionaries';
let getwaybilltotialPageUrl = 'waybill/getwaybilltotial';
let createwaybillPageUrl = 'waybill/createwaybill';
let getwaybillDetailPageUrl = 'waybill/getwaybillDetail';
let waybillSearchPageUrl = 'waybill/waybillSearch';
let getwaybillTrajectoryPageUrl = 'waybill/getwaybillTrajectory';
let getmywaybillPageUrl = 'waybill/getmywaybill';
let getfreightcalculationPageUrl = 'waybill/getfreightcalculation';
let getcouponsPageUrl = 'waybill/getcoupons';
let getcouponPageUrl = 'waybill/getcoupon';
let getMyCouponsPageUrl = 'waybill/getMyCoupons';
let waybillBindPostCodePageUrl = "waybill/waybillBindPostCode";
let waybillBindPostCodeListPageUrl = "waybill/waybillBindPostCodeList";
let deleteWaybillBindPostCodePageUrl = "waybill/deleteWaybillBindPostCode";
let waybillPayPageUrl = "waybill/waybillPay";
let waybillPayParamPageUrl = "waybill/waybillPayParam";
let waybillPayStatusPageUrl = "waybill/waybillPayStatus";
let waybillPayTestPageUrl = "waybill/waybillPayTest";
let waybillCacelPageUrl = "waybill/waybillCacel";
let waybillDeletePageUrl = "waybill/waybillDelete";
let getwaybillbarCodePageUrl = "waybill/getwaybillbarCode";

let loginpwPageUrl = '/user/loginpw';
let userAddressListPageUrl = 'user/getAddressList';
let seaveAddressPageUrl = 'user/seaveAddress';
let getAddressDetailPageUrl = 'user/getAddressDetail';
let deleteAddressPageUrl = 'user/deleteAddress';
let setAddressDefaultPageUrl = 'user/setAddressDefault';
let userDetailPageUrl = 'user/getUserDetail';
let userWalletDetailPageUrl = 'user/getUserWalletDetail';
let userbillListPageUrl = 'user/getbillList';
let userintegralbillListPageUrl = 'user/getintegralbillList';
let setUserNickNamePageUrl = 'user/updateNickName';
let setUserNamePageUrl = 'user/updateName';
let setUserSexPageUrl = 'user/updateSex';
let getSignListPageUrl = 'user/getSignList';
let getUserAuthenticationPageUrl = 'user/getUserAuthentication';
let updateAuthenticationPageUrl = 'user/updateAuthentication';
let uploadIdCardPageUrl = 'user/uploadIdCard';
let getTodayIsSignPageUrl = 'user/getTodayIsSign';
let signPageUrl = 'user/sign';
let rechargeItemsPageUrl = 'user/getrechargeItems';
let uploaduserheadPageUrl = 'user/uploaduserhead';
let seaveusersimpPageUrl = 'user/seaveusersimp';

let createRechargePageUrl = 'recharge/create';
let payRechargePageUrl = 'recharge/pay';
let getrechargeDetailPageUrl = 'recharge/detail';
let getrechargePayPageUrl = 'recharge/getrechargePay';
let getrechargeStatusPageUrl = 'recharge/getrechargeStatus';

let getproductsPageUrl = 'mall/getproducts';
let getproductdetailPageUrl = 'mall/getproductdetail';
let createorderPageUrl = 'mall/createorder';
let getorderdetailPageUrl = 'mall/getorderdetail';
let getordersPageUrl = 'mall/getorders';
let getorderStatuslistPageUrl = 'mall/getorderStatuslist';
let getorderpayparamPageUrl = 'mall/orderPayParam';
let getorderPayStatusPageUrl = 'mall/orderPayStatus';

let orderBalancePayPageUrl = 'mall/orderBalancePay';
let orderPayPageUrl = 'mall/orderPay';
let orderPayTestPageUrl = 'mall/orderPayTest';
let orderSignPageUrl = 'mall/orderSign';
let orderCacelPageUrl = 'mall/orderCacel';
let addCartProductUrl = 'mall/addCartProduct';
let deleteCartProductUrl = 'mall/deleteCartProduct';
let updateCartProductUrl = 'mall/updateCartProduct';
let getCartProductUrl = 'mall/getCartProduct';

const install = () => {
	// 此处没有使用传入的params参数
	let weixinLogin = (params = {}) => post(weixinLoginUrl, qs.stringify(params));

	// 获取微信openid
	let getwxopenId = (params = {}) => post(getwxopenIdUrl, qs.stringify(params));

	// 小程序登录
	let weixinxcxLogin = (params = {}) => post(weixinxcxLoginUrl, qs.stringify(params));

	// 小程序注册登录
	let weixinxcxRegistered = (params = {}) => post(weixinxcxRegisteredUrl, qs.stringify(params));

	//发送短信验证码
	let sendVercode = (params = {}) => post(sendVercodeUrl, qs.stringify(params));

	//发送找回密短信验证码
	let sendPassWordVercode = (params = {}) => post(sendPassWordVercodeUrl, qs.stringify(params));

	// 短信修改密码
	let vercodeChangePassword = (params = {}) => post(vercodeChangePasswordUrl, qs.stringify(params));

	//手机快捷登录
	let vercodeLogin = (params = {}) => post(vercodeLoginUrl, qs.stringify(params));

	//注册
	let registered = (params = {}) => post(registerUrl, qs.stringify(params));

	// 获取广告内容
	let getPosters = (params = {}) => get(postersUrl, params);

	// 获取文章分类列表
	let getArticlecategory = (params = {}) => post(articlecategoryUrl, qs.stringify(params));

	// 获取文章内容
	let getArticle = (params = {}) => get(articleUrl, params);

	// 获取文章列表
	let getArticles = (params = {}) => post(articlesUrl, qs.stringify(params));

	// 获取文章列表
	let getArticlesPage = (params = {}) => post(articlesPageUrl, qs.stringify(params));

	// 获取加拿大省市区
	let getCitys2 = (params = {}) => post(getCitys2PageUrl, qs.stringify(params));


	// 帐号密码登录
	let loginpw = (params = {}) => post(loginpwPageUrl, qs.stringify(params));

	// 获取用户详细信息
	let getuserDetail = (params = {}) => get(userDetailPageUrl, params);

	// 获取钱包信息
	let getuserWalletDetail = (params = {}) => post(userWalletDetailPageUrl, qs.stringify(params));

	// 获取账单列表
	let getuserbillList = (params = {}) => post(userbillListPageUrl, qs.stringify(params));

	// 获取积分账单列表
	let getintegralbillList = (params = {}) => post(userintegralbillListPageUrl, qs.stringify(params));

	//设置昵称
	let setuserNickName = (params = {}) => post(setUserNickNamePageUrl, qs.stringify(params));

	//设置姓名
	let setuserName = (params = {}) => post(setUserNamePageUrl, qs.stringify(params));

	//设置性别
	let setuserSex = (params = {}) => post(setUserSexPageUrl, qs.stringify(params));

	//获取会员认证信息
	let getUserAuthentication = (params = {}) => post(getUserAuthenticationPageUrl, qs.stringify(params));

	//保存身份认证图片
	let uploadIdCard = (params = {}) => post(uploadIdCardPageUrl, qs.stringify(params));

	//保存身份认证
	let updateAuthentication = (params = {}) => post(updateAuthenticationPageUrl, qs.stringify(params));

	// 获取用户地址列表
	let userAddressList = (params = {}) => post(userAddressListPageUrl, qs.stringify(params));

	// 添加新地址
	let seaveAddress = (params = {}) => post(seaveAddressPageUrl, qs.stringify(params));

	// 获取地址明细
	let getAddressDetail = (params = {}) => post(getAddressDetailPageUrl, qs.stringify(params));

	// 删除地址
	let deleteAddress = (params = {}) => post(deleteAddressPageUrl, qs.stringify(params));

	// 设为默认地址
	let setAddressDefault = (params = {}) => post(setAddressDefaultPageUrl, qs.stringify(params));

	// 获取签到列表
	let getSignList = (params = {}) => post(getSignListPageUrl, qs.stringify(params));

	// 获取今天是否签到
	let getTodayIsSign = (params = {}) => post(getTodayIsSignPageUrl, qs.stringify(params));

	// 签到
	let sign = (params = {}) => post(signPageUrl, qs.stringify(params));

	//上传头像
	let uploaduserhead = (params = {}) => post(uploaduserheadPageUrl, params);

	//保存用户信息
	let seaveusersimp = (params = {}) => post(seaveusersimpPageUrl, qs.stringify(params));

	// 获取物流单字典
	let getwaybilldictionaries = (params = {}) => post(getwaybilldictionariesPageUrl, qs.stringify(params));

	// 获取充值项列表
	let rechargeItems = (params = {}) => post(rechargeItemsPageUrl, qs.stringify(params));

	// 物流单价格
	let getwaybilltotial = (params = {}) => post(getwaybilltotialPageUrl, qs.stringify(params));

	// 物流单下单
	let createwaybill = (params = {}) => post(createwaybillPageUrl, qs.stringify(params));

	// 物流单明细
	let getwaybillDetail = (params = {}) => post(getwaybillDetailPageUrl, qs.stringify(params));

	//物流单搜索
	let waybillSearch = (params = {}) => post(waybillSearchPageUrl, qs.stringify(params));

	//物流单扭转信息
	let getwaybillTrajectory = (params = {}) => post(getwaybillTrajectoryPageUrl, qs.stringify(params));

	// 获取我的物流单
	let getmywaybill = (params = {}) => post(getmywaybillPageUrl, qs.stringify(params));

	// 运单价格计算
	let getfreightcalculation = (params = {}) => post(getfreightcalculationPageUrl, qs.stringify(params));

	//运单绑定快递单
	let waybillBindPostCode = (params = {}) => post(waybillBindPostCodePageUrl, qs.stringify(params));

	//运单绑定快递单列表
	let waybillBindPostCodeList = (params = {}) => post(waybillBindPostCodeListPageUrl, qs.stringify(params));

	//运单绑定快递单删除
	let deleteWaybillBindPostCode = (params = {}) => post(deleteWaybillBindPostCodePageUrl, qs.stringify(params));

	// 运单支付
	let waybillPay = (params = {}) => post(waybillPayPageUrl, qs.stringify(params));

	//运单支付参数
	let waybillPayParam = (params = {}) => post(waybillPayParamPageUrl, qs.stringify(params));

	//运单状态
	let waybillPayStatus = (params = {}) => post(waybillPayStatusPageUrl, qs.stringify(params));

	// 运单支付测试
	let waybillPayTest = (params = {}) => post(waybillPayTestPageUrl, qs.stringify(params));

	// 运单取消
	let waybillCacel =  (params = {}) => post(waybillCacelPageUrl, qs.stringify(params));

	// 运单删除
	let waybillDelete =  (params = {}) => post(waybillDeletePageUrl, qs.stringify(params));

	// 运单自打码列表
	let getwaybillbarCode = (params = {}) =>  post(getwaybillbarCodePageUrl, qs.stringify(params)); 

	// 优惠券列表
	let getcoupons = (params = {}) => post(getcouponsPageUrl, qs.stringify(params));

	// 领取优惠券
	let getcoupon = (params = {}) => post(getcouponPageUrl, qs.stringify(params));

	// 获取我的优惠券
	let getMyCoupons = (params = {}) => post(getMyCouponsPageUrl, qs.stringify(params));

	// 充值订单
	let createRecharge = (params = {}) => post(createRechargePageUrl, qs.stringify(params));

	// 充值订单支付
	let payRecharge = (params = {}) => post(payRechargePageUrl, qs.stringify(params));

	// 充值订单明细
	let getrechargeDetail = (params = {}) => post(getrechargeDetailPageUrl, qs.stringify(params));

	// 充值订单支付参数
	let getrechargePay = (params = {}) => post(getrechargePayPageUrl, qs.stringify(params));

	// 充值订单支付状态
	let getrechargeStatus = (params = {}) => post(getrechargeStatusPageUrl, qs.stringify(params));

	// 商品列表
	let getproducts = (params = {}) => post(getproductsPageUrl, qs.stringify(params));

	// 商品详细
	let getproductdetail = (params = {}) => post(getproductdetailPageUrl, qs.stringify(params));

	// 创建订单
	let createorder = (params = {}) => post(createorderPageUrl, qs.stringify(params));

	// 获取订单明细
	let getorderdetail = (params = {}) => post(getorderdetailPageUrl, qs.stringify(params));

	// 获取订单列表
	let getorders = (params = {}) => post(getordersPageUrl, qs.stringify(params));

	//获取订单状态列表
	let getorderStatuslist = (params = {}) => post(getorderStatuslistPageUrl, qs.stringify(params));

	// 获取订单支付参数
	let getorderpayparam = (params = {}) => post(getorderpayparamPageUrl, qs.stringify(params));

	// 获取订单支付状态
	let getorderPayStatus = (params = {}) => post(getorderPayStatusPageUrl, qs.stringify(params));

	// 订单余额支付
	let orderBalancePay = (params = {}) => post(orderBalancePayPageUrl, qs.stringify(params));

	//订单支付
	let orderPay = (params = {}) => post(orderPayPageUrl, qs.stringify(params));

	//订单支付测试
	let orderPayTest = (params = {}) => post(orderPayTestPageUrl, qs.stringify(params));

	//订单签收
	let orderSign = (params = {}) => post(orderSignPageUrl, qs.stringify(params));

	//订单取消
	let orderCacel = (params = {}) => post(orderCacelPageUrl, qs.stringify(params));

	//添加商品到购物车
	let addCartProduct = (params = {}) => post(addCartProductUrl, qs.stringify(params));

	//删除购物车商品
	let deleteCartProduct = (params = {}) => post(deleteCartProductUrl, qs.stringify(params));

	//修改购物车商品
	let updateCartProduct = (params = {}) => post(updateCartProductUrl, qs.stringify(params));

	//得到购物车商品
	let getCartProduct = (params = {}) => post(getCartProductUrl, qs.stringify(params));

	let apiUrl = 'http://webapi.diyunlife.com'; //API的路径
	let onLineServerUrl = 'http://imweb.diyunlife.com'; //在线客服路径

	Vue.prototype.api = {
		getwxopenId,
		weixinLogin,
		weixinxcxLogin,
		weixinxcxRegistered,
		sendVercode,
		sendPassWordVercode,
		vercodeChangePassword,
		vercodeLogin,
		registered,
		getPosters,
		getArticlecategory,
		getArticle,
		getArticles,
		getArticlesPage,
		getCitys2,
		loginpw,
		userAddressList,
		seaveAddress,
		getAddressDetail,
		deleteAddress,
		setAddressDefault,
		getwaybilldictionaries,
		getwaybilltotial,
		createwaybill,
		getwaybillDetail,
		waybillSearch,
		getmywaybill,
		getuserDetail,
		getuserWalletDetail,
		getwaybillTrajectory,
		getuserbillList,
		getintegralbillList,
		getfreightcalculation,
		waybillBindPostCode,
		waybillBindPostCodeList,
		deleteWaybillBindPostCode,
		waybillPay,
		waybillPayTest,
		waybillPayParam,
		waybillPayStatus,
		waybillCacel,
		waybillDelete,
		getwaybillbarCode,
		getcoupons,
		getcoupon,
		getMyCoupons,
		setuserNickName,
		setuserName,
		setuserSex,
		getUserAuthentication,
		uploadIdCard,
		updateAuthentication,
		getSignList,
		getTodayIsSign,
		sign,
		uploaduserhead,
		seaveusersimp,
		rechargeItems,
		createRecharge,
		payRecharge,
		getrechargeDetail,
		getrechargePay,
		getrechargeStatus,
		getproducts,
		getproductdetail,
		createorder,
		getorderdetail,
		getorders,
		getorderStatuslist,
		getorderpayparam,
		getorderPayStatus,
		orderBalancePay,
		orderPay,
		orderPayTest,
		orderSign,
		orderCacel,
		addCartProduct,
		deleteCartProduct,
		updateCartProduct,
		getCartProduct,
		onLineServerUrl,
		apiUrl,
	};
}

export default {
	install
}

//微信扫码登录
export async function getwxopenId(code) {
	return await post(getwxopenIdUrl, qs.stringify({ code: code }));
}

export async function weixinLogin(openid) {
	return await post(weixinLoginUrl, qs.stringify({ openid: openid }));
}

export async function getuserDetail() {
	return await post(userDetailPageUrl, qs.stringify({}));
}