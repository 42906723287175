import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

//点击重复路由时报错处理
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    title: "迪韵生活",
    requireAuth: false,
  },
},
{
  path: '/about',
  name: 'About',
  component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
  meta: {
    title: "迪韵生活",
    requireAuth: false,
  },
},
{
  path: '/news',
  name: 'News',
  component: () => import( /* webpackChunkName: "news" */ '../views/News.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/charge',
  name: 'Charge',
  component: () => import( /* webpackChunkName: "charge" */ '../views/Charge.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/mall',
  name: 'Mall',
  component: () => import( /* webpackChunkName: "mall" */ '../views/Mall.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/product',
  name: 'Product',
  component: () => import( /* webpackChunkName: "product" */ '../views/Product.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/shoppingCart',
  name: 'ShoppingCart',
  component: () => import( /* webpackChunkName: "shoppingCart" */ '../views/ShoppingCart.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/buy',
  name: 'Buy',
  component: () => import( /* webpackChunkName: "buy" */ '../views/Buy.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/orderPaymethod',
  name: 'OrderPaymethod',
  component: () => import( /* webpackChunkName: "orderPaymethod" */ '../views/OrderPaymethod.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/orderWechatPay',
  name: 'OrderWechatPay',
  component: () => import( /* webpackChunkName: "orderWechatPay" */ '../views/OrderWechatPay.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/orderPayResule',
  name: 'OrderPayResule',
  component: () => import( /* webpackChunkName: "orderPayResule" */ '../views/OrderPayResule.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/user',
  name: 'User',
  component: () => import( /* webpackChunkName: "user" */ '../views/User.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/post',
  name: 'Post',
  component: () => import( /* webpackChunkName: "post" */ '../views/Post.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/viewbarCode',
  name: 'ViewbarCode',
  component: () => import( /* webpackChunkName: "ViewbarCode" */ '../views/ViewbarCode.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/service',
  name: 'Service',
  component: () => import( /* webpackChunkName: "service" */ '../views/Service.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/trace',
  name: 'Trace',
  component: () => import( /* webpackChunkName: "trace" */ '../views/Trace.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/intracity',
  name: 'Intracity',
  component: () => import( /* webpackChunkName: "trace" */ '../views/Intracity.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/intracityItems',
  name: 'IntracityItems',
  component: () => import( /* webpackChunkName: "trace" */ '../views/IntracityItems.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/strategy',
  name: 'Strategy',
  component: () => import( /* webpackChunkName: "trace" */ '../views/Strategy.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/strategyItems',
  name: 'StrategyItems',
  component: () => import( /* webpackChunkName: "trace" */ '../views/StrategyItems.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/login',
  name: 'Login',
  component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/register',
  name: 'Register',
  component: () => import( /* webpackChunkName: "register" */ '../views/Register.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/forget',
  name: 'Forget',
  component: () => import( /* webpackChunkName: "forget" */ '../views/Forget.vue'),
  meta: {
    requireAuth: false,
  },
},
{
  path: '/user/addressbook',
  name: 'Addressbook',
  component: () => import( /* webpackChunkName: "addressbook" */ '../views/user/Addressbook.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/addressbookAdd',
  name: 'AddressbookAdd',
  component: () => import( /* webpackChunkName: "addressbookAdd" */ '../views/user/AddressbookAdd.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/bonuspoints',
  name: 'Bonuspoints',
  component: () => import( /* webpackChunkName: "bonuspoints" */ '../views/user/Bonuspoints.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/blances',
  name: 'Blances',
  component: () => import( /* webpackChunkName: "bonuspoints" */ '../views/user/Blances.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/topUp',
  name: 'TopUp',
  component: () => import( /* webpackChunkName: "bonuspoints" */ '../views/user/TopUp.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/topUpPaymethod',
  name: 'TopUpPaymethod',
  component: () => import( /* webpackChunkName: "bonuspoints" */ '../views/user/TopUpPaymethod.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/topUpWechatPay',
  name: 'TopUpWechatPay',
  component: () => import( /* webpackChunkName: "bonuspoints" */ '../views/user/TopUpWechatPay.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/user/topUpPayResule',
  name: 'TopUpPayResule',
  component: () => import( /* webpackChunkName: "bonuspoints" */ '../views/user/TopUpPayResule.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/depositresult',
  name: 'DepositResult',
  component: () => import( /* webpackChunkName: "bonuspoints" */ '../views/user/DepositResult.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/blancesLog',
  name: 'BlancesLog',
  component: () => import( /* webpackChunkName: "blancesLog" */ '../views/user/BlancesLog.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/getcoupons',
  name: 'Getcoupons',
  component: () => import( /* webpackChunkName: "getcoupons" */ '../views/user/Getcoupons.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/sign',
  name: 'Sign',
  component: () => import( /* webpackChunkName: "sign" */ '../views/user/Sign.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/coupons',
  name: 'Coupons',
  component: () => import( /* webpackChunkName: "coupons" */ '../views/user/Coupons.vue'),
  meta: {
    requireAuth: true,
  },
}, {
  path: '/user/info',
  name: 'Info',
  component: () => import( /* webpackChunkName: "coupons" */ '../views/user/Info.vue'),
  meta: {
    requireAuth: true,
  },
}, {
  path: '/user/mypost',
  name: 'Mypost',
  component: () => import( /* webpackChunkName: "mypost" */ '../views/user/Mypost.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/user/myreceive',
  name: 'Myreceive',
  component: () => import( /* webpackChunkName: "myreceive" */ '../views/user/Myreceive.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/waybillDetail',
  name: 'WaybillDetail',
  component: () => import( /* webpackChunkName: "WaybillDetail" */ '../views/WaybillDetail.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/waybillWechatPay',
  name: 'WaybillWechatPay',
  component: () => import( /* webpackChunkName: "WaybillDetail" */ '../views/WaybillWechatPay.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/waybillPaymethod',
  name: 'WaybillPaymethod',
  component: () => import( /* webpackChunkName: "WaybillDetail" */ '../views/WaybillPaymethod.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '/waybillPayResule',
  name: 'WaybillPayResule',
  component: () => import( /* webpackChunkName: "WaybillDetail" */ '../views/WaybillPayResule.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/post/success',
  name: 'Success',
  component: () => import( /* webpackChunkName: "success" */ '../views/post/Success.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/user/myorder',
  name: 'Myorder',
  component: () => import( /* webpackChunkName: "myorder" */ '../views/user/Myorder.vue'),
  meta: {
    requireAuth: true,
  },
},{
  path: '/orderDetail',
  name: 'OrderDetail',
  component: () => import( /* webpackChunkName: "orderDetail" */ '../views/OrderDetail.vue'),
  meta: {
    requireAuth: true,
  },
},
{
  path: '*',
  redirect: '/'
}
]

const router = new VueRouter({
  routes
})

import { getItem, setItem } from '/common/localStorage.js';
import { getwxopenId, weixinLogin, getuserDetail } from '/common/http.api.js';

router.beforeEach((to, from, next) => {
  //页面标题赋值
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "迪韵生活";
  }

  //获取登录token
  const token = getItem('token') || "";
  //微信自动登录
  if (to.query.code) {
    const wxcode = getItem('wxcode') || "";
    if (wxcode != to.query.code || (!token || token == "")) {
      getwxopenId(to.query.code).then((res) => {
        setItem("wxcode", to.query.code);//保存微信登录code
        let openid = res.openid;
        weixinLogin(openid).then((res2) => {
          setItem("token", res2.access_token);
          setItem("user", null);
          getuserDetail().then((res3) => {
            setItem("user", res3);
            next({ path: '/user' })
          }).catch((err) => {
            console.log(err);
          });
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  // 判断该路由是否需要登录权限
  if (to.meta.requireAuth) {
    if (token && token != "") {
      // 判断该路由是否需要管理员权限
      next()
    } else {
      next({ path: '/login' })
    }
  }
  else {
    next()
  }
})


export default router