import axios from 'axios' // 引入axios
import { notification } from 'ant-design-vue';
import { message } from 'ant-design-vue';
import { getItem } from '/common/localStorage.js';
import router from '/src/router'

const instance = axios.create({
  baseURL: 'http://webapi.diyunlife.com',
  //baseURL: 'http://localhost:5050',
  timeout: 60000,
  header: {
    'Accept': 'application/json, text/plain, */*',
    'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
  }
})

// 请求拦截器
instance.interceptors.request.use(function (config) {
  //console.log('发请求之前', config)
  //config.headers['access_token'] ='application/x-www-form-urlencoded;charset=UTF-8';
  let token = getItem('token');
  if (token) {
    config.headers['access_token'] = token;
  }
  return config
}, function (error) {
  //console.log('请求错误', error)
  return error
})

// 响应拦截器
instance.interceptors.response.use(function (res) {
  if (res.status == 200) {
    if (res.data.code == 0) {
      return res.data;
    } else if (res.data.code == 1001) {
      notification.open({
        message: '提示信息',
        type: 'error',
        description: '您的授权已过期。请重新登录',
        onClose:()=>{
          router.push('/login');
        },
        onClick: () => {
          router.push('/login');
        },
      });
      return false;
    } else {
      message.config({top: `40%`});
      message.error(res.data.msg);
      return false;
    }
  } else if (res.status == 1001) {
    notification.open({
      message: '提示信息',
      type: 'error',
      description: '您的授权已过期。请重新登录',
      onClose:()=>{
        router.push('/login');
      },
      onClick: () => {
        router.push('/login');
      },
    });
    return false;
  } else {
    notification.open({
      message: '错误',
      type: 'error',
      description: res.data.msg,
    });
    return false;
  }
}, function (error) {
  //console.log('响应错误', error)
  return error
})

// get请求
export function get(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params: data }).then((response) => {
      if (response) {
        resolve(response.data)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

// post请求
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then((response) => {
      if (response) {
        resolve(response.data)
      }
    }, (err) => {
      reject(err)
    })
  })
}