// storage封装
import Vue from 'vue'

const STORAGE_KEY = 'DYPCMALL'

const install = () => {
  //存储值
  let setItem = (key, value, module_name) => {
    if (module_name) {
      let val = getItem(module_name)
      val[key] = value
      setItem(module_name, val)
    } else {
      // 只传入了两个参数
      let val = getStorage();
      val[key] = value;
      window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(val))
    }
  };

  //获取某一个模块下的属性（user下面的userName）
  let getItem = (key, module_name) => {
    if (module_name) {
      let val = getItem(module_name);
      if (val) return val[key];

    }
    return getStorage()[key];
  };

  // 获取整个数据（转为json格式）：将数据转换为json格式
  let getStorage = () => {
    return JSON.parse(window.sessionStorage.getItem(STORAGE_KEY) || '{}')
  };

  // 删除某一个值
  let clear = (key, module_name) => {
    let val = getStorage();
    if (module_name) {
      delete val[module_name][key];
    } else {
      delete val[key]
    }
    setItem(val);
  };

  Vue.prototype.storage = {
    setItem,
    getItem,
    getStorage,
    clear,
  };
}

export default {
  install
}

export function getItem(key, module_name){
  if (module_name) {
    let val = getItem(module_name);
    if (val) return val[key];
  }
  let res = JSON.parse(window.sessionStorage.getItem(STORAGE_KEY) || '{}')
  return res[key];
}

export function setItem(key, value, module_name){
  if (module_name) {
    let val = getItem(module_name)
    val[key] = value
    setItem(module_name, val)
  } else {
    // 只传入了两个参数
    let res = JSON.parse(window.sessionStorage.getItem(STORAGE_KEY) || '{}')
    res[key] = value;
    window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(res))
  }
}