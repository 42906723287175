import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Vue.use(Antd)
import { Button, Icon, Layout, Menu, PageHeader, Carousel, Row, Col, Tabs, Form, FormModel, Input, Radio, Checkbox, Avatar, Card, Table, Popconfirm, Slider, Modal, Message, Space, Badge, Pagination, List, Empty, Spin, InputNumber, Select, Cascader, Switch, Result, Calendar, Upload ,Collapse} from 'ant-design-vue';
Vue.use(Button)
Vue.use(Icon)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(PageHeader)
Vue.use(Carousel)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tabs)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Avatar)
Vue.use(Card)
Vue.use(Table)
Vue.use(Popconfirm)
Vue.use(Slider)
Vue.use(Modal)
Vue.use(Message)
Vue.use(Space)
Vue.use(Badge)
Vue.use(Pagination)
Vue.use(List)
Vue.use(Empty)
Vue.use(Spin)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Cascader)
Vue.use(Switch)
Vue.use(Result)
Vue.use(Calendar)
Vue.use(Upload)
Vue.use(Collapse)

Vue.prototype.$message = Message;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;

Vue.config.productionTip = false

// http接口API抽离，免于写url或者一些固定的参数
import httpApi from '/common/http.api.js';
Vue.use(httpApi);

// 本地缓存
import storage from '/common/localStorage.js';
Vue.use(storage);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


