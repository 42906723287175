<template>
  <footer>
    <div>
      <a-row type="flex">
        <a-col span="4" style="padding-left: 20px;" @click="go">
          <img alt="迪韵" src="../assets/logo.png" />
        </a-col>
        <a-col span="10">
          <a-row type="flex" justify="space-between" align="top">
            <dl class="foot-nav">
              <dt>物流服务</dt>
              <dd>
                <router-link to="/News?id=1">国际物流</router-link>
              </dd>
              <dd>
                <router-link to="/News?id=1">海运服务</router-link>
              </dd>
              <dd>
                <router-link to="/News?id=1">空运服务</router-link>
              </dd>
              <dd>
                <router-link to="/News?id=1">快递服务</router-link>
              </dd>
            </dl>
            <dl class="foot-nav">
              <dt>合作伙伴</dt>
              <dd>
                <router-link to="/News?id=1">国内</router-link>
              </dd>
              <dd>
                <router-link to="/News?id=1">加拿大</router-link>
              </dd>
            </dl>
            <dl class="foot-nav">
              <dt>关于我们</dt>
              <dd>
                <router-link to="/about">关于迪韵</router-link>
              </dd>
              <dd>
                <router-link to="/News?id=1">合作资讯</router-link>
              </dd>
            </dl>
          </a-row>
        </a-col>
        <a-col span="10">
          <div class="foolImgs">
            <div class="foolImg">
              <div class="title">关注公众号</div>
              <div><img :src="code1" /></div>
            </div>
            <div class="foolImg">
              <div class="title">关注小程序</div>
              <div><img :src="code2" /></div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
</footer>
</template>
<script>

export default {
  name: "foot",
  data() {
    return {
      code1: require("../assets/code1.jpg"),
      code2: require("../assets/code2.jpg"),
    };
  },
  methods: {
    go() {
      this.$router.push('/Home');
    },
  },
};
</script>
<style lang="less">
footer {
  margin-top: 20px;
  padding: 20px 0;
  background: #282828;
  color: #fff;
  border-top: 8px solid #dc1e32;

  dl {
    line-height: 2;

    dt {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    dd {
      a {
        color: #FFF;
      }

      a:hover {
        color: #dc1e32;
      }

    }
  }

  .foolImgs {
    display: flex;

    .foolImg {
      padding-left: 100px;
      padding-top: 5px;

      .title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 10px;
      }

      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
</style>