<template>
  <div class="page">
    <Nav></Nav>
    <main class="home">
      <a-carousel autoplay arrows>
        <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px; zindex: 1">
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" />
        </div>
        <div v-for="(item, index) in list" :key="index" :style="{ backgroundImage: 'url(' + item.filename + ')' }"
          class="silde-item" @click="bannerClick(item.url)"></div>
      </a-carousel>
      <div class="quick">
        <div class="wrap">
          <a-row type="flex" align="middle">
            <a-col :span="6">
              <div class="item">
                <router-link slot="extra" :to="`/trace`">
                  <a-icon type="search" class="ico" />运单追踪
                </router-link>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="item">
                <router-link slot="extra" :to="`/post`">
                  <a-icon type="plus-square" class="ico" />我要寄件
                </router-link>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="item">
                <router-link slot="extra" :to="`/service`">
                  <a-icon type="environment" class="ico" />网点查询
                </router-link>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="item">
                <a href="javascript:;" @click="openServer()">
                  <a-icon type="customer-service" class="ico" />在线客服
                </a>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="main">
        <div class="wrap">
          <div class="title">物流服务介绍</div>
          <nav class="tabs">
            <a-tabs default-active-key="1" size="large" :tabBarGutter="200">
              <a-tab-pane key="1" tab="海运服务" class="tab-item">
                <div class="list-item" v-for="(item, index) in service1" :key="index">
                  <img :src="item.filename" alt="" class="cover" />
                  <div class="content">
                    <div class="title">
                      {{ item.name }}
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAPCAYAAAGq+G6YAAAAAXNSR0IArs4c6QAAAPdJREFUKBWNUbsKwjAUTYIWHARBEEU3d5e2dBN3Bzd19B+c9Hvc1VnclbaUOljq4E+0g1SknitNECNo4XIf53GTlHme5zD5+b6/lHWRgZ+oFEW/eGXXdQfFgDGoxi84z/M5g+CiIGoQQzngsqAM4Mg536oZBicyo0EJjQPDimVZPcXA3qtqikIYhtEH+/YJsDAMG1DcNSAIghoUDwLkxTSSLodfR1v07UicNmdZdoBpEzGybXunL2BMvQdsWyDsEV3EBLfevAsUUQ6jKKqnaUquPSHEzDTNFWEaUQriOG4nSXJGXwW5rBF/Or6fEf9tCpe1dKf8962fNuR+NTfMUbcAAAAASUVORK5CYII="
                        alt="" />
                    </div>
                    <div class="description">
                      <div>
                        {{ item.explain }}
                      </div>
                    </div>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="空运服务" class="tab-item">
                <div class="list-item" v-for="(item, index) in service2" :key="index">
                  <img :src="item.filename" alt="" class="cover" />
                  <div class="content">
                    <div class="title">
                      {{ item.name }}
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAPCAYAAAGq+G6YAAAAAXNSR0IArs4c6QAAAPdJREFUKBWNUbsKwjAUTYIWHARBEEU3d5e2dBN3Bzd19B+c9Hvc1VnclbaUOljq4E+0g1SknitNECNo4XIf53GTlHme5zD5+b6/lHWRgZ+oFEW/eGXXdQfFgDGoxi84z/M5g+CiIGoQQzngsqAM4Mg536oZBicyo0EJjQPDimVZPcXA3qtqikIYhtEH+/YJsDAMG1DcNSAIghoUDwLkxTSSLodfR1v07UicNmdZdoBpEzGybXunL2BMvQdsWyDsEV3EBLfevAsUUQ6jKKqnaUquPSHEzDTNFWEaUQriOG4nSXJGXwW5rBF/Or6fEf9tCpe1dKf8962fNuR+NTfMUbcAAAAASUVORK5CYII="
                        alt="" />
                    </div>
                    <div class="description">
                      <div>
                        {{ item.explain }}
                      </div>
                    </div>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="3" tab="电子商务" class="tab-item">
                <div class="list-item" v-for="(item, index) in service3" :key="index">
                  <img :src="item.filename" alt="" class="cover" />
                  <div class="content">
                    <div class="title">
                      {{ item.name }}
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAPCAYAAAGq+G6YAAAAAXNSR0IArs4c6QAAAPdJREFUKBWNUbsKwjAUTYIWHARBEEU3d5e2dBN3Bzd19B+c9Hvc1VnclbaUOljq4E+0g1SknitNECNo4XIf53GTlHme5zD5+b6/lHWRgZ+oFEW/eGXXdQfFgDGoxi84z/M5g+CiIGoQQzngsqAM4Mg536oZBicyo0EJjQPDimVZPcXA3qtqikIYhtEH+/YJsDAMG1DcNSAIghoUDwLkxTSSLodfR1v07UicNmdZdoBpEzGybXunL2BMvQdsWyDsEV3EBLfevAsUUQ6jKKqnaUquPSHEzDTNFWEaUQriOG4nSXJGXwW5rBF/Or6fEf9tCpe1dKf8962fNuR+NTfMUbcAAAAASUVORK5CYII="
                        alt="" />
                    </div>
                    <div class="description">
                      <div>
                        {{ item.explain }}
                      </div>
                    </div>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="4" tab="同城服务" class="tab-item">
                <div class="list-item" v-for="(item, index) in service4" :key="index">
                  <img :src="item.filename" alt="" class="cover" />
                  <div class="content">
                    <div class="title">
                      {{ item.name }}
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAPCAYAAAGq+G6YAAAAAXNSR0IArs4c6QAAAPdJREFUKBWNUbsKwjAUTYIWHARBEEU3d5e2dBN3Bzd19B+c9Hvc1VnclbaUOljq4E+0g1SknitNECNo4XIf53GTlHme5zD5+b6/lHWRgZ+oFEW/eGXXdQfFgDGoxi84z/M5g+CiIGoQQzngsqAM4Mg536oZBicyo0EJjQPDimVZPcXA3qtqikIYhtEH+/YJsDAMG1DcNSAIghoUDwLkxTSSLodfR1v07UicNmdZdoBpEzGybXunL2BMvQdsWyDsEV3EBLfevAsUUQ6jKKqnaUquPSHEzDTNFWEaUQriOG4nSXJGXwW5rBF/Or6fEf9tCpe1dKf8962fNuR+NTfMUbcAAAAASUVORK5CYII="
                        alt="" />
                    </div>
                    <div class="description">
                      <div>
                        {{ item.explain }}
                      </div>
                    </div>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
          </nav>
        </div>
      </div>
      <div class="main">
        <div class="wrap">
          <div class="news">
            <div class="title">
              <span class="title-main">新闻资讯</span>
              <a href="" class="" v-if="false"> 查看全部 </a>
            </div>
            <div class="list">
              <div class="list-item" v-for="(item, index) in listArticle" :key="index"  @click="gonews(item.id)">
                <img :src="item.filename" :alt="item.title" class="cover" />
                <div class="content">
                  <div class="title">
                    <span> {{ item.name }} </span>
                  </div>
                  <div class="description">
                    {{ item.explain }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <foot></foot>
    <a-modal v-model="visible" title="在线客服" @ok="hideService" :footer="null" :width="800">
      <div style="height:600px">
        <iframe :src="[serverLink]" frameborder="0" align="left" width="100%" height="600" scrolling="no"></iframe>
      </div>
    </a-modal>
  </div>
</template>

<script>

// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";

export default {
  name: "Home",
  components: {
    Nav,
    foot,
  },
  data() {
    return {
      list: [],
      service1: [],
      service2: [],
      service3: [],
      service4: [],
      listArticle: [],
      visible: false,
      serverLink: '',
    };
  },
  created() {
    //this.storage.setItem("token","");
    this.loadData();
  },
  methods: {
    //请求数据
    loadData() {
      //banner广告
      this.api
        .getPosters({
          id: 6,
          simple: false,
        })
        .then((res) => {
          this.list = res;
        })
        .catch((err) => {
          console.log(err);
        });
      //服务1
      this.api
        .getArticles({
          id: 1028,
          num: 2,
        })
        .then((res) => {
          this.service1 = res;
        })
        .catch((err) => {
          console.log(err);
        });
      //服务2
      this.api
        .getArticles({
          id: 1029,
          num: 2,
        })
        .then((res) => {
          this.service2 = res;
        })
        .catch((err) => {
          console.log(err);
        });
      //服务3
      this.api
        .getArticles({
          id: 1030,
          num: 2,
        })
        .then((res) => {
          this.service3 = res;
        })
        .catch((err) => {
          console.log(err);
        });
      //服务4
      this.api
        .getArticles({
          id: 1031,
          num: 2,
        })
        .then((res) => {
          this.service4 = res;
        })
        .catch((err) => {
          console.log(err);
        });
      //新闻资讯
      this.api
        .getArticles({
          id: 1027,
          num: 4,
        })
        .then((res) => {
          this.listArticle = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bannerClick(url) {
      if (url) {
        this.$router.push(url);
      }
    },
    //新闻链接
    gonews(id){
      this.$router.push('/News?id='+id);
    },
    openServer() {
      this.serverLink = this.api.onLineServerUrl + '/index.html?id=' + new Date().getTime();

      console.log(this.serverLink)

      this.visible = true;
    },
    hideService() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.ant-carousel>>>.slick-slide {
  text-align: center;
  height: 690px;

  overflow: hidden;
}

.silde-item {
  height: 690px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-carousel>>>.custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel>>>.custom-slick-arrow:before {
  display: none;
}

.ant-carousel>>>.custom-slick-arrow:hover {
  opacity: 0.5;
}

.quick {
  height: 100px;
  background: #dc1e32;
  width: 100%;
  text-align: center;
  color: #fff;
}

.quick .item {
  height: 100px;
  display: flex;
  -webkit-box-pack: center;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  color: #fff;
  font-size: 0.16rem;
  background: url("../assets/itembg.png") no-repeat 100%;
}

.quick .item .ico {
  font-size: 24px;
  padding-right: 10px;
}

.quick .item a {
  width: 100%;
  height: 100%;
  min-height: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  /* -webkit-justify-content: center; */
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
}

.quick .item a:hover {
  background: #fff;
  color: #dc1e32;
}

.main {
  background: #fff;
  margin-top: 50px;
}

.main .title {
  font-size: 32px;
  color: #333;
  font-weight: 700;
  text-align: center;
}

.main .tabs {
  margin-top: 50px;
  width: 100%;
  height: 450px;
}

.main .tabs .tab-item {
  width: 1200px;
  margin: 50px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main .tabs .list-item:hover .content .description,
.main .tabs .list-item:hover .content .title {
  color: #dc1e32;
}

.main .tabs .list-item {
  cursor: pointer;
  object-fit: cover;
  width: 580px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  overflow: hidden;
  min-height: calc(100% - 16px);
}

.main .tabs .list-item img {
  width: 100%;
  height: 220px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.main .tabs .list-item .content {
  padding: 20px 20px 30px;
}

.main .tabs .list-item .content .title {
  color: #000;
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
}

.main .tabs .list-item .content .title>img {
  width: 10px;
  height: 15px;
}

.main .tabs .list-item .content .description {
  margin-top: 7px;
  font-size: 14px;
  color: #999;
  text-align: left;
}

.main .news {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.main .news .title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.main .news .title .title-main {
  font-size: 32px;
  color: #333;
  font-weight: 700;
}

.main .news .list .list-item .content .title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  color: #333;
  font-weight: 700;
}

.main .news .title a {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 98px;
  height: 34px;
  line-height: 34px;
  border-radius: 16px;
  border: 1px solid #b5b5b5;
  font-size: 0.14rem;
  color: #666;
  text-align: center;
}

.main .news .list .list-item,
.main .news .list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;
}

.main .news .list {
  margin-top: 50px;
}

.main .news .list .list-item {
  cursor: pointer;
  width: calc(50% - 30px);
  height: 150px;
  margin-bottom: 2px;
  overflow: hidden;
  border-radius: 0.6px;
  background-color: #fff;
}

.main .news .list .list-item:hover .content .description,
.main .news .list .list-item:hover .content .title span {
  color: #fff;
}

.main .news .list .list-item:hover {
  background-color: #dc1e32;
}

.main .news .list .list-item .cover {
  width: 210px;
  height: 100%;
  overflow: hidden;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.main .news .list .list-item .content {
  width: 360px;
  padding: 15px 32px 15px 22px;
}

.main .news .list .list-item .content .description {
  margin-top: 0.12rem;
  font-size: 0.14rem;
  color: #666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.main .news .list .list-item .cover {
  width: 210px;
  height: 100%;
  overflow: hidden;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.bar {
  height: 200px;
  background: #fcd846;
}

.bar .txt {
  margin-left: 40px;
}

.bar li {
  line-height: 1.8;
}

.serv-item {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}

.serv-item:hover {
  background: #f3cb29;
}

.case {
  background: #333333;
  height: 510px;
}

.about {
  height: 440px;
}

.tabs {
  text-align: center !important;
}

.tab-item {
  color: #fcd846;
}
</style>