<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {

  }
</script>



<style lang="less">
  @import '~ant-design-vue/es/style/v2-compatible-reset.css';
  @import '~ant-design-vue/dist/antd.less';
  @import '@/assets/css.less';

  html,
  body {
    height: 100%;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #dc1e32 !important;
    font-weight: bold !important;
  }

  .ant-tabs-ink-bar {
    background-color: #dc1e32 !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #dc1e32 !important;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 1200px;

    height: 100%;
  }

  .wrap {
    width: 1200px;
    margin: 0 auto;
  }

  // * {
  //   outline: 1px dashed #ffa7a5;
  // }

  .page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 100vh;
    min-height: 900px;

    main {
      //height: 100%;
      flex: 1;
    }
  }

  .b-btm {
    border-bottom: 1px solid rgb(235, 237, 240)
  }

  .u-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>